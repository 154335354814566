// colors
$color-white: #ffffff;

$color-black1: #000;
$color-black2: #55555e;
$color-black3: #2b2a36;

$color-grey1: #eaeaea;

$color-main-normal: #36b7bd;
$color-main-hover: #2d9ea3;
$color-main-light: #cef4f4;
$color-main-extralight: #ebfbfb;

$color-accent-normal: #f9807d;
$color-accent-hover: #db5c58;
$color-accent-light: #ffdcda;

$color-secondary-light: #f3f7ff;
$color-secondary-medium: #d4dffa;
$color-secondary-dark: #585b8a;

$color-border: #e0e8f6;
$color-hr: #dddddf;

$color-main-gradient1: linear-gradient(270deg, #a3df73 -79.31%, #36b7bd 118.5%);
$color-main-gradient2: linear-gradient(270deg, #07bedc -42.63%, #0389b6 100%);
$color-main-gradient3: linear-gradient(
  270deg,
  #a3df73 -123.67%,
  #36b7bd 172.45%
);
$color-main-gradient4: linear-gradient(17.56deg, #46bea6 3.86%, #a3df73 100%);
$color-main-gradient5: linear-gradient(
  270deg,
  #a3df73 -123.67%,
  #36b7bd 172.45%
);

// font sizes
$text-xxl: 46px;
$text-xl: 30px;
$text-l: 23px;
$text-m: 20px;
$text-s: 17px;
$text-xs: 15px;

//32px - number in app-point-panel

// 26px - banner on pages (desktop)

// 24px - h1 mobile

// 12px - footer (mobile), coockie (mobile), button with arrow on pricing page disk space block (mobile)
// form label, placeholder (mobile)
// user profile in header
// cookie

// font weights
$weight-regular: 400;
$weight-semi-bold: 600;
$weight-bolder: 800;

// @media-w
$width-xs: 576px;
$width-m: 768px;
$width-l: 992px;
$width-xl: 1024px;
$width-xxl: 1200px;

// @media-h
$height-l: 800px;

// default transition time
$transition-time: 0.3s;

//shadow
$shadow-submenu: 0px 2px 20px rgba(0, 0, 0, 0.1);
$shadow-box: 0px 0px 5.54056px rgba(0, 0, 0, 0.15);

// border
$border-radius: 15px;
