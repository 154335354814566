@import "const.scss";

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

html,
body {
  height: 100%;
}

body,
textarea,
input,
button {
  font-family: "Open Sans", "Arial", sans-serif;
}

body {
  min-width: 320px;
  font-size: $text-s;
  font-style: normal;
  font-weight: $weight-regular;
  color: $color-black3;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-black3;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

p,
div {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body .ui-button:enabled:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

body a {
  color: $color-main-normal;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: $color-main-hover;
  }
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.container {
  max-width: $width-xxl;
  padding: 0 30px;
  margin: 0 auto;

  @media (max-width: $width-xs) {
    padding: 0 15px;
  }
}

.mb-block {
  margin-bottom: 110px;

  @media (max-width: $width-m) {
    margin-bottom: 80px;
  }

  @media (max-width: $width-xs) {
    margin-bottom: 40px;
  }
}

.form-checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 3px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $color-main-normal;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.form-checkbox-error {
  border: 1px solid $color-accent-normal !important;
}

.number {
  width: 50px;
  height: 50px;
  background-color: $color-main-normal;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 150%;
  color: $color-white;
  flex: none;
  margin-right: 30px;

  @media (max-width: $width-xs) {
    font-size: 26px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
}

.vissually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.btn-transparent {
  background: $color-white;
  border: 1px solid $color-accent-normal;
  border-radius: 5px;
  color: $color-accent-normal;
  padding: 7px 30px;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: $text-xs;
  font-weight: $weight-semi-bold;
  line-height: 150%;
  cursor: pointer;

  &:hover {
    border-color: $color-accent-hover;
    color: $color-accent-hover;
  }
}

.btn-fill {
  outline: none;
  background: $color-accent-normal;
  border: none;
  border-radius: 5px;
  color: $color-white;
  padding: 10px 40px;
  text-decoration: none;
  font-size: $text-xs;
  font-weight: $weight-semi-bold;
  line-height: 150%;
  box-shadow: 0px 5px 10px rgba(249, 128, 125, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: $color-accent-hover;
    color: $color-white;
  }

  @media (max-width: $width-xs) {
    font-size: $text-xs !important;
    padding: 10px 25px;
  }
}

.btn-with-arrow {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
  color: $color-accent-normal;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;

  & span {
    margin-left: 5px;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  & span svg path {
    transition: all 0.3s ease;
  }

  &:hover {
    color: $color-accent-hover;

    & span {
      margin-left: 8px;
    }
    & span svg path {
      fill: $color-accent-hover;
    }
  }

  @media (max-width: $width-xs) {
    font-size: $text-xs;

    &:after {
      top: 5px;
    }

    svg {
      width: 15px;
    }
  }
}

.btn-large {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: $color-accent-normal;
  border-color: $color-accent-normal;
  background-color: $color-white;
  outline: none;
  border-radius: 4px;
  border: 1px solid $color-accent-normal;
  height: 50px;
  padding: 0 10px;
  transition: $transition-time;

  &:hover {
    border-color: $color-accent-hover;
    color: $color-accent-hover;
  }

  @media (max-width: $width-xs) {
    font-size: $text-xs;
    height: 38px;
  }
}

h1 {
  font-size: $text-xxl;
  font-weight: $weight-bolder;
  color: $color-black3;

  @media (max-width: $width-xs) {
    font-size: 24px;
  }
}

h2 {
  font-size: $text-xl;
  color: $color-black3;

  @media (max-width: $width-xs) {
    font-weight: 600;
    font-size: $text-l;
  }
}

h3 {
  font-size: $text-l;
  font-weight: 600;

  @media (max-width: $width-xs) {
    font-size: $text-m;
  }
}

@mixin input {
  width: 100%;
  background-color: rgba(54, 183, 189, 0.05);
  border: none !important;
  border-radius: 3px;
  padding: 14px 20px !important;
  font-size: 17px;
  line-height: 150%;
  outline: none !important;
  box-shadow: none !important;

  @media (max-width: $width-xs) {
    font-size: $text-xs;
  }
}

@mixin searchInput {
  padding-right: 40px !important;
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-image: url("/assets/icons/search.svg");
}

.input-wrapper {
  width: 100%;
  position: relative;

  & > input {
    @include input;
  }

  &.search > input {
    @include searchInput;
  }
}

.input {
  @include input;

  &.search {
    @include searchInput;
  }
}

@mixin btn {
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $transition-time;

  &:hover {
    opacity: 1;
  }
}

@mixin btnLarge {
  padding: 10px 40px;
  font-size: 20px;
  height: 50px;

  @media (max-width: $width-xs) {
    padding: 10px 24px;
    font-size: 15px;
    height: 43px;
  }
}

@mixin btnMedium {
  padding: 8px 30px;
  font-size: 15px;
  height: 38px;
}

@mixin btnFillRed {
  color: $color-white;
  background-color: $color-accent-normal;

  &:hover {
    color: $color-white;
    background-color: $color-accent-hover;
  }
}

@mixin btnFillGreen {
  color: $color-white;
  background-color: $color-main-normal;

  &:hover {
    color: $color-white;
    background-color: $color-main-hover;
  }
}

@mixin btnFillWhiteRed {
  background-color: $color-white;
  color: $color-accent-normal;

  &:hover {
    color: $color-accent-hover;
    border-color: $color-accent-hover;
  }
}

@mixin btnFillWhiteGreen {
  background-color: $color-white;
  color: $color-main-normal;

  &:hover {
    color: $color-main-hover;
    border-color: $color-main-hover;
  }
}

@mixin btnTransparentWhite {
  background-color: transparent;
  color: $color-white;
  border-color: $color-white;

  &:hover {
    color: $color-main-hover;
    background-color: $color-white;
  }
}

@mixin btnTransparentRed {
  background-color: transparent;
  color: $color-accent-normal;
  border-color: $color-accent-normal;

  &:hover {
    color: $color-white;
    background-color: $color-accent-hover;
    border-color: $color-accent-hover;
  }
}

.btn-fill-red-large {
  @include btn;
  @include btnLarge;
  @include btnFillRed;
}

.btn-fill-red-medium {
  @include btn;
  @include btnMedium;
  @include btnFillRed;
}

.btn-fill-green-medium {
  @include btn;
  @include btnMedium;
  @include btnFillGreen;
}

.btn-fill-green-large {
  @include btn;
  @include btnLarge;
  @include btnFillGreen;
}

.btn-fill-white-to-red-medium {
  @include btn;
  @include btnMedium;
  @include btnFillWhiteRed;
}

.btn-fill-white-to-green-large {
  @include btn;
  @include btnLarge;
  @include btnFillWhiteGreen;
}

.btn-transparent-to-white-large {
  @include btn;
  @include btnLarge;
  @include btnTransparentWhite;
}

.btn-transparent-to-red-medium {
  @include btn;
  @include btnMedium;
  @include btnTransparentRed;
}

.btn-transparent-to-red-large {
  @include btn;
  @include btnLarge;
  @include btnTransparentRed;
}
